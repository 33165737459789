<template>
  <div class="mx-auto sm:w-2/3 p-4 container select-none">
    <div class="grid grid-cols-4 sm:grid-cols-2 gap-2">
      <div>
        <label>Key</label>
      </div>
      <div>
        <select v-model="selectedKey" v-on:change="getNotes" class="border-2 border-gray-200 rounded w-full py-0.5 px-1">
          <option v-for="key in keyNames" v-bind:key="key.id" v-bind:value="key.id">
            {{ key.key }}
          </option>
        </select>
      </div>
      <div>
        <label>Type</label>
      </div>
      <div>
        <select v-model="selectedType" v-on:change="getNotes" class="border-2 border-gray-200 rounded w-full py-0.5 px-1">
          <option v-for="item in keyTypes" v-bind:key="item.id" v-bind:value="item.keyType">
            {{ item.keyType }}
          </option>
        </select>
      </div>
      <div>
        <label>Begin</label>
      </div>
      <div>
        <input type="number" min=1 max=7 v-model="scaleDegreeBegin" class="border-2 border-gray-200 rounded w-full px-2">
      </div>
      <div>
        <label>End</label>
      </div>
      <div>
        <input type="number" min=1 max=7 v-model="scaleDegreeEnd" class="border-2 border-gray-200 rounded w-full px-2">
      </div>
    </div>
    <div class="grid grid-cols-2 sm:grid-cols-2 gap-2 mt-2">
      <div>
        <label>Sequence Length</label>
      </div>
      <div>
        <input type="number" min=1 max=32 v-model="sequenceLength" class="border-2 border-gray-200 rounded w-full px-2">
      </div>
    </div>
    <div class="grid grid-cols-1 sm:grid-cols-2 gap-2 mt-2">
      <div>
        <button v-on:click="calculate" class="bg-pantone1807-500 text-white font-bold px-4 py-2 rounded w-full">Random Sequence</button>
      </div>
      <div class="flex justify-center space-x-2">
        <button v-on:click="showNotes = !showNotes" :class="computedNoteStatus" class="text-white font-bold px-4 py-2 rounded w-full">Show Notes</button>
        <button v-on:click="toggleTonicMode" :class="computedTonicMode" class="text-white font-bold px-4 py-2 rounded w-full">Tonic Mode</button>
      </div>
    </div>
    <div v-if="sequence" @mousedown="setShowNotesTrue" @mouseup="setShowNotesFalse" @touchstart="setShowNotesTrue" @touchend="setShowNotesFalse" class="flex flex-col mt-2 py-2  bg-red-200 rounded select-none">
      <div class="flex justify-center">
        <Dice :data="viewableDegrees" styling="font-bold bg-white text-pantone1807-700" />
      </div>
      <div v-if="showNotes" class="pt-2">
        <Dice :data="viewableNotes" styling="font-bold bg-pantone1807-700 text-white"  />
      </div>
    </div>
    <div class="flex justify-center">
      <p v-if="sequence" class="text-sm text-gray-400">Press to toggle notes.</p>
    </div>
  </div>
</template>

<script>
import Dice from "@/components/Dice.vue"

let keys = [
  ['C', 'D', 'E', 'F', 'G', 'A', 'B'],
  ['F', 'G', 'A', 'Bb', 'C', 'D', 'E', ],
  ['Bb', 'C', 'D', 'Eb', 'F', 'G', 'A'],
  ['Eb', 'F', 'G', 'Ab', 'Bb', 'C', 'D'],
  ['Ab', 'Bb', 'C', 'Db', 'Eb', 'F', 'G'],
  ['Db', 'Eb', 'F', 'Gb', 'Ab', 'Bb', 'C'],
  ['Gb', 'Ab', 'Bb', 'Cb', 'Db', 'Eb', 'F'],
  ['F#', 'G#', 'A#', 'B', 'C#', 'D#', 'E#'],
  ['B', 'C#', 'D#', 'E', 'F#', 'G#', 'A#'],
  ['E', 'F#', 'G#', 'A', 'B', 'C#', 'D#'],
  ['A', 'B', 'C#', 'D', 'E', 'F#', 'G#'],
  ['D', 'E', 'F#', 'G', 'A', 'B', 'C#'],
  ['G', 'A', 'B', 'C', 'D', 'E', 'F#']
]

function flattenNote (note) {
  let result = note
  if (note.length == 2) {
    if (note.charAt(1) == '#') {
      result = note.charAt(0)
    } else {
      result = note + 'b'
    }
  } else {
    result = note + 'b'
  }
  return result
}

function naturalMinor (key) {
  let minor = [...key]
  minor[2] = flattenNote(minor[2])
  minor[5] = flattenNote(minor[5])
  minor[6] = flattenNote(minor[6])
  return minor
}

function harmonicMinor (key) {
  let minor = [...key]
  minor[2] = flattenNote(minor[2])
  minor[5] = flattenNote(minor[5])
  return minor
}

function dorianMode (key) {
  let minor = [...key]
  minor[2] = flattenNote(minor[2])
  minor[6] = flattenNote(minor[6])
  return minor
}

function mixolydianMode (key) {
  let minor = [...key]
  minor[6] = flattenNote(minor[6])
  return minor
}

function getRandomIntInclusive (minimum, maximum) {
  minimum = Math.ceil(minimum);
  maximum = Math.floor(maximum);
  //The maximum is inclusive and the minimum is inclusive
  return Math.floor(Math.random() * (maximum - minimum + 1) + minimum);
}

export default {
  name: 'Dashboard',
  components: {
    Dice
  },
  data () {
    return {
      scaleDegreeBegin: 1,
      scaleDegreeEnd: 3,
      sequenceLength: 3,
      sequence: undefined,
      keyNames: [
        {key: 'C',  id: 0},
        {key: 'F',  id: 1},
        {key: 'Bb', id: 2},
        {key: 'Eb', id: 3},
        {key: 'Ab', id: 4},
        {key: 'Db', id: 5},
        {key: 'Gb', id: 6},
        {key: 'F#', id: 7},
        {key: 'B',  id: 8},
        {key: 'E',  id: 9},
        {key: 'A',  id: 10},
        {key: 'D',  id: 11},
        {key: 'G',  id: 12},
      ],
      keyTypes: [
        {keyType: 'Major',           id: 0},
        {keyType: 'Natural Minor',   id: 1},
        {keyType: 'Harmonic Minor',  id: 2},
        {keyType: 'Dorian Mode',     id: 3},
        {keyType: 'Mixolydian Mode', id: 4},
      ],
      selectedKey: 0,
      selectedType: 'Major',
      viewableDegrees: undefined,
      viewableNotes: undefined,
      showNotes: false,
      tonicMode: false
    }
  },
  computed: {
    computedNoteStatus: function () {
      return this.showNotes ? "bg-pantone1807-700" : "bg-pantone1807-500"
    },
    computedTonicMode: function () {
      return this.tonicMode ? "bg-pantone1807-700" : "bg-pantone1807-500"
    }
  },
  methods: {
    calculate: function () {
      this.generateSequence()
      this.getNotes()
    },
    generateSequence: function () {
      let result = new Array(this.sequenceLength)
      for (let i = 0; i < this.sequenceLength; i++) {
        result[i] = getRandomIntInclusive(this.scaleDegreeBegin, this.scaleDegreeEnd)
      }
      if (this.tonicMode) {
        result.splice(0, 1, 1)
      }
      this.sequence = result
      this.viewableDegrees = result.map(String)
    },
    getNotes: function () {
      if (this.sequence == undefined) {
        return
      }
      let result = new Array(this.sequenceLength)
      let key = keys[this.selectedKey]
      switch (this.selectedType) {
        case 'Natural Minor':
          key = naturalMinor(key)
          break
        case 'Harmonic Minor':
          key = harmonicMinor(key)
          break
        case 'Dorian Mode':
          key = dorianMode(key)
          break
        case 'Mixolydian Mode':
          key = mixolydianMode(key)
          break
      }
      for (let i = 0; i < this.sequenceLength; i++) {
        result[i] = key[this.sequence[i] - 1]
      }
      this.viewableNotes = result
    },
    toggleTonicMode: function () {
      this.tonicMode = !this.tonicMode
      this.calculate()
    },
    setShowNotesTrue: function () {
        this.showNotes = true
    },
    setShowNotesFalse: function () {
        this.showNotes = false
    }
  }
}
</script>
