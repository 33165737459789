<template>
  <div class="flex flex-row justify-center space-x-2">
    <div v-for="(item, index) in data" :key="index">
      <div :class="styling" class="flex justify-center items-center rounded w-8 h-8">{{item}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dice',
  props: {
    data: Array,
    styling: String
  }
}
</script>